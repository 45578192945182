// AUTO REGISTERED PROVIDERS
const getRegisteredProviders = () => {
  if (typeof window === 'undefined')
    return [];
  if (!window.webbtc_stx_providers)
    return [];
  return window.webbtc_stx_providers;
};
const getInstalledProviders = (defaultProviders = []) => {
  if (typeof window === 'undefined')
    return [];
  const registeredProviders = getRegisteredProviders();
  const additionalInstalledProviders = defaultProviders.filter(defaultProvider => {
    // already registered, don't add again
    if (registeredProviders.find(rp => rp.id === defaultProvider.id))
      return false;
    // check if default provider is installed (even if not registered)
    const provider = getProviderFromId(defaultProvider.id);
    return !!provider;
  });
  return registeredProviders.concat(additionalInstalledProviders);
};
const getProviderFromId = (id) => {
  return id === null || id === void 0 ? void 0 : id.split('.').reduce((acc, part) => acc === null || acc === void 0 ? void 0 : acc[part], window);
};

const LOCAL_STORAGE_KEY = 'STX_PROVIDER';
const getSelectedProviderId = () => {
  if (typeof window === 'undefined')
    return null;
  return window.localStorage.getItem(LOCAL_STORAGE_KEY);
};
const setSelectedProviderId = (provider) => {
  if (typeof window !== 'undefined') {
    window.localStorage.setItem(LOCAL_STORAGE_KEY, provider);
  }
};
const clearSelectedProviderId = () => {
  if (typeof window !== 'undefined') {
    window.localStorage.removeItem(LOCAL_STORAGE_KEY);
  }
};

export { getInstalledProviders as a, getProviderFromId as b, getSelectedProviderId as c, clearSelectedProviderId as d, getRegisteredProviders as g, setSelectedProviderId as s };
